
<template>
  <div>
    <a-modal title="修改申请单价" v-model="visible" width="40%" @ok="onConfirm" :maskClosable="false" okText="确定">
      <a-form-model ref="form" :model="rowData" :rules="formRule" :label-col="{ span: 6 }" :wrapper-col="{ span: 10 }">
        <a-form-model-item label="折扣率(%)" prop="supplierDiscount">
          <a-input-number :min="0" style="width: 100%;" :step="0.1" v-model="rowData.supplierDiscount"  />
          <span>如：85折，只需输入85即可</span>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>

export default {

  data() {
    return {
      visible: false,
      rowData: {},
      // 表单验证
      formRule: {
        supplierDiscount: [{ required: true, message: '请选择折扣率', trigger: 'change' }],
      },
    }
  },

  computed: {},

  created() {},

  methods: {
    isShow(row) {
      this.rowData = {}
      this.visible = true
    },

    onConfirm() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }
        this.visible = false
        this.$emit('getPrice',this.rowData)
      })
    },
  },
}
</script>

<style lang='scss' scoped>
</style>