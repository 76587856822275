<template>
  <a-spin :spinning="spinning">
  <a-card :border="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <OrderSearch :showOrderStatus="'to_audit_order'" @getSearchData="getSearchData" ref="OrderSearch"></OrderSearch>
      <div>
        <a-button v-if="isCheck" type="primary" @click="toHandler('check')">查看</a-button>
        <a-button v-if="isAudit && selectedRows.length > 0 && selectedRows[0].orderStatus == 0 && selectedRows[0].taskId != null" type="primary" @click="toHandler('audit')">审核</a-button>
        <a-button
          v-if="isEditAudit && selectedRows.length === 1 && selectedRows[0].orderStatus == 0 && selectedRows[0].taskId != null"
          type="primary"
          @click="toHandler('edit_audit')"
        >修改订单</a-button
        >
        <!-- <a-button v-if="selectedRows.length > 0 && selectedRows[0].orderStatus == 0" type="default" @click="toHandler('rollbackStatus')" icon="rollback">撤审</a-button> -->
        <downLoad v-if="isExport" method="post" api="/api/order/order/orderInfo/excelExportFirmOrderList" :params="searchDataObj" @downLoadDone="downLoadDone" @startDownLoad="startDownLoad" name="事务所采购菱感商品订单(待审核).xls">批量导出订单</downLoad>
      </div>
      <!-- 数据表格 -->
      <OrderList
        :url="'/api/order/order/orderInfo/firmOrderList'"
        :columnsData="columns"
        :status="[0]"
        @orderData="getSelectedRows($event)"
        @orderDataKey="getKeys"
        @changeTable="getchangeTable"
      ></OrderList>

      <!-- 编辑表单 -->
      <OrderInfo-edit-modal ref="OrderInfoEditModal"></OrderInfo-edit-modal>
      <EditAddressModal ref="EditAddressModal"></EditAddressModal>
      <InvoiceEditModal ref="InvoiceEditModal"></InvoiceEditModal>
      <OrderInfoCheckModal ref="OrderInfoCheckModal"></OrderInfoCheckModal>
      <OrderInfoEditAndAutid ref="OrderInfoEditAndAutid"  @getConfirmDiscount="getConfirmDiscount"></OrderInfoEditAndAutid>

      <a-modal
        title="操作"
        width="30%"
        :visible="visible"
        :confirm-loading="confirmLoading"
        :footer="null"
        @cancel="visible = false"
        :maskClosable="false"
      >
        <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
          <div v-if="handle == 'audit'">
            <a-form-model-item label="是否通过">
              <a-radio-group v-model="status" @change="onStatusChange">
                <a-radio :value="4"> 通过 </a-radio>
                <a-radio :value="3"> 拒绝 </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="原因" v-if="statusRadio == 3">
              <a-textarea v-model="rejectReason"></a-textarea>
            </a-form-model-item>
          </div>
          <div v-if="handle == 'cancel' || handle == 'void'">
            <a-form-model-item label="理由">
              <a-textarea v-model="rejectReason"></a-textarea>
            </a-form-model-item>
          </div>
        </a-form-model>
        <div class="footer-bts">
          <a-button type="default" @click="visible = false">关闭</a-button>
          <a-button v-if="handle == 'void'" type="primary" @click="onModifyOrderStatus('void')">作废</a-button>
          <a-button v-if="handle == 'cancel'" type="primary" @click="onModifyOrderStatus('cancel')">取消订单</a-button>
          <a-button v-if="handle == 'audit'" type="danger" @click="onModifyOrderStatus('refuse')">拒绝</a-button>
          <a-button v-if="handle == 'audit'" type="primary" @click="onModifyOrderStatus('audit')">通过</a-button>
        </div>
      </a-modal>
    </div>
  </a-card>
  </a-spin>
</template>

<script>
import OrderInfoEditModal from '@/views/order_info/components/OrderInfoEditModal.vue'
import OrderInfoEditAndAutid from '@/views/order_info/components/OrderInfoEditAndAutid.vue'
import { delOrderInfo, rollbackStatus } from './api/OrderInfoApi'
import EditAddressModal from './components/EditAddressModal.vue'
import InvoiceEditModal from './components/InvoiceEditModal.vue'
import OrderInfoCheckModal from '@/views/order_info/components/OrderInfoCheckModal.vue'
import { checkPermission } from '@/utils/permissions'

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'orderInfo',
  components: {
    OrderInfoEditModal,
    EditAddressModal,
    OrderInfoEditAndAutid,
    InvoiceEditModal,
    OrderInfoCheckModal,
  },
  data() {
    return {
      columns: [
        'orderCode',
        'linkGapContractNum',
        'stContractNum',
        'bigAreaName',
        'dealerName',
        'flagFreeShipping',
        'deliveryProperties',
        'purchaseAndSalesContractType',
        'contractType',
        'orderType',
        'sourceType',
        'payAmount',
        'orderStatus',
        'expectTime',
        'createTime',
        'address',
        'consigneeType',
        'supplierName',
        'policyTotalAmount',
        'rebateTotalAmount',
        'alreadySendGoodsCount',
        'bucklePoint',
        'goodsCount'
      ],
      tableData: [],
      searchData: {statusList: [0]}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      isCheck: checkPermission('order:info:check'),
      isAudit: checkPermission('order:info:audit'),
      isExport: checkPermission('order:info:export'),
      isEditAudit: checkPermission('order:info:edit_audit'),
      visible: false,
      confirmLoading: false,
      rejectReason: '',
      handle: '',
      contractTypeList: [],
      orderTypeList: [],
      status: 4,
      statusRadio: 4,
      idsList:[],
      spinning:false
    }
  },
computed:{
    searchDataObj(){
      let obj = {
        ...this.searchData,
        ids:this.idsList
      }
      return obj

    }
  },
  methods: {
    //调查询列表
    getConfirmDiscount(){
      this.$refs.OrderSearch.getDataFilter()
    },
    startDownLoad(){
      this.spinning = true
    },
    downLoadDone (){
      this.spinning = false
    },
    getchangeTable(){
      this.$refs.OrderSearch.getDataFilter(1)
    },
      // 导出条件
    getSearchData(row){
     this.searchData = row
      this.searchData.statusList = [0]
      this.idsList = []
    },
     getKeys(row){
     this.idsList = row
    },
    // 隔行换色
    rowClassName(record, index) {
      let className = 'light-row'
      if (index % 2 === 1) className = 'dark-row'
      return className
    },

    getSelectedRows(rows) {
      this.selectedRows = rows
    },

    toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.OrderInfoEditModal.setRowData({}, 'add')
        return
      }
      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'edit':
          _this.$refs.OrderInfoEditModal.setRowData(_this.selectedRows[0], 'edit')
          break
        case 'edit_audit':
          _this.$refs.OrderInfoEditAndAutid.setRowData(_this.selectedRows[0], 'edit_audit')
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delOrderInfo(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'rollbackStatus':
          _this.$confirm({
            title: '撤回警告',
            content: '确认要撤回此条记录的提交审核吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await rollbackStatus(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.$refs.OrderSearch.getDataFilter()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'edit_address':
          _this.$refs.EditAddressModal.isShow(_this.selectedRows[0])
          break
        case 'edit_invoice':
          _this.$refs.InvoiceEditModal.isShow(_this.selectedRows[0])
          break
        case 'audit':
          _this.$refs.OrderInfoCheckModal.isShow(_this.selectedRows[0], 'audit')
          break
        case 'void':
          _this.visible = true
          _this.handle = 'void'
          break
        case 'cancel':
          _this.visible = true
          _this.handle = 'cancel'
          break
        case 'check':
          _this.$refs.OrderInfoCheckModal.isShow(_this.selectedRows[0])
          break
      }
    },

    // 修改订单状态
    onModifyOrderStatus(name) {
      let status = 0
      if (name == 'audit') {
        status = 4
      } else if (name == 'refuse') {
        status = 3
      } else if (name == 'void') {
        status = 1
      } else if (name == 'cancel') {
        status = 2
      }
      let obj = {
        orderId: this.selectedRows[0].id,
        orderStatus: status,
        verifyRemark: this.rejectReason,
      }
      this.axios.post(`/api/order/order/orderInfo/verifyOrderInfo`, obj).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.message)
          this.visible = false
          this.$refs.OrderSearch.getDataFilter()
          this.rejectReason = ''
          this.selectedRowKeys = []
        }
      })
    },

    onStatusChange(val) {
      this.statusRadio = val.target.value
    },
  },
  created() {
    // this.getData()
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
::v-deep .ant-table-tbody > .light-row {
  background: #fff !important;
}
::v-deep .ant-table-tbody > .dark-row {
  background: #fafafa !important;
}
.footer-bts {
  text-align: right;
}
</style>
